import i18n from "@/plugins/i18n";
export default {
  browse: {
    table: {
      headers: [
        {
          text: i18n.t("model.prop.no"),
          value: "index",
          sortable: false,
        },
        {
          text: i18n.t("model.prop.name"),
          value: "teacherName",
        },
        {
          text: 'Institution',
          value: "Institution",
        },
        {
          text: i18n.t("model.prop.mobile"),
          value: "User.Account.mobile",
        },
        {
          text: "Position",
          value: "PositionName",
        },
        {
          text: "Joined",
          value: "joinedStatus",
        },
        {
          text: "",
          value: "transferTeacher",
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: "Info",
        label: i18n.t("string.info"),
      },
      //  {
      //   key: 'TeacherInstitution',
      //   label:  'Institutions',
      // },
    ],
    table: {
      headers: [
        {
          text: i18n.t("model.prop.name"),
          value: "User.name",
        },
        {
          text: i18n.t("model.prop.mobile"),
          value: "User.Account.mobile",
        },
        {
          text: "Position",
          value: "PositionName",
        },
        {
          text: i18n.t("model.name.school"),
          value: "Institution",
          sortable: false,
          filterable: false,
        },
        {
          text: "Joined",
          value: "joinedStatusRead",
        },
        {
          text: i18n.t("model.prop.created"),
          value: "timestampCreated",
          filterable: false,
        },
        {
          text: i18n.t("model.prop.lastUpdated"),
          value: "timestampUpdated",
          filterable: false,
        },
      ],
    },
  },
  add: {
    fields: [
      {
        type: "string",
        key: "name",
        name: i18n.t("model.prop.name"),
      },
      {
        type: "string",
        key: "mobile",
        name: i18n.t("model.prop.mobile"),
      },
    ],
    rules: {
      name: [(v) => !!v || i18n.t("rules.name")],
    },
  },
  edit: {
    fields: [
      {
        type: 'model',
        key: 'positionId',
        name: 'Position',
        model: 'position',
        optionText: 'name',
        server:'sso',
        optionValue: 'id',
      },
    ],
    rules: {
      positionId: [(v) => !!v || "Position is required"],
    },
  },
  delete: true,
};
