import Admin from '@/models/bread/Admin/teacher'
import Moderator from '@/models/bread/Moderator/teacher'
import Teacher from '@/models/bread/Teacher/teacher'

export default {
  key:'Teacher',
  name:{
    singular:'Teacher',
    plural:'Teachers',
  },
  parents:[
    'Institution',
  ],
  Admin:Admin,
  Moderator:Moderator,
  Teacher:Teacher,
}